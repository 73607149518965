:root {
  --wepredic-color: #7052FF;
  --wepredic-dark: #322182;
  --wepredic-dark-transparent: #332182d4;
  --wepredic-dark-light: #d5ccff;
  --biopredic-color: #03BF8C;
  --biopredic-dark: #014C38;
  --starlight-color: #000000;
  --starlight-dark: #000405;
  --eurosafe-color: #4D8CF0;
  --eurosafe-dark: #28569F;
  --eurosafe-dark-transparent: #28569fd5;
  --biopredic-advancells-color: #00CEBF;
  --biopredic-advancells-dark: #005751;
  --starlight-color-light: #10FFFF;

}

body {
  font-family: 'Inter' !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.overbar {
  height: 45px !important;
  z-index: 99;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.3s;
  z-index: 1000;
  /* Assurez-vous que l'élément est au-dessus des autres */
  box-shadow: 1px 1px 8px 5px #acacac12;

}

.starlight-header {
  background-color: transparent;
  color: #00131880;
  transition: all 0.3s;
  border-radius: 50px;
  margin-right: 10px;
  padding: 5px 15px;
  font-size: 14px;
}

.eurosafe-header {
  background-color: transparent;
  color: #00131880;
  transition: all 0.3s;
  border-radius: 50px;
  margin-right: 10px;
  padding: 5px 15px;
  font-size: 14px;

}

.biopredic-header {
  background-color: transparent;
  color: #00131880;
  transition: all 0.3s;
  border-radius: 50px;
  margin-right: 10px;
  padding: 5px 15px;
  font-size: 14px;

}

.advancells-header {
  background-color: transparent;
  color: #00131880;
  transition: all 0.3s;
  border-radius: 50px;
  margin-right: 10px;
  padding: 5px 15px;
  font-size: 14px;
}

.starlight-header:hover {
  background-color: var(--starlight-color);
  color: #FFFFFF;
  transition: all 0.3s;
}

.eurosafe-header:hover {
  background-color: var(--eurosafe-color);
  color: #FFFFFF;
  transition: all 0.3s;
}

.biopredic-header:hover {
  background-color: var(--biopredic-color);
  color: #FFFFFF;
  transition: all 0.3s;
}

.advancells-header:hover {
  background-color: var(--biopredic-advancells-color);
  color: #FFFFFF;
  transition: all 0.3s;
}

.primary-button {
  margin-top: 20px;
  border-radius: 20px !important;
  padding: 12px 56px !important;
  border: none;
  transition: all 0.3s;
  width: fit-content;
}

.primary-button.theme-1 {
  background-color: var(--wepredic-color);
  color: white;
  border: 1px solid var(--wepredic-color)
}

.primary-button.theme-1:hover {
  background-color: white;
  color: var(--wepredic-color);
  border: 1px solid var(--wepredic-color)
}



.primary-button.theme-2 {
  background-color: var(--biopredic-color);
  color: white;
  border: 1px solid var(--biopredic-color)
}

.primary-button.theme-2:hover {
  background-color: white;
  color: var(--biopredic-color);
  border: 1px solid var(--biopredic-color)
}

.primary-button.theme-3 {
  background-color: var(--starlight-color);
  color: white;
  border: 1px solid var(--starlight-color)
}

.primary-button.theme-3:hover {
  background-color: white;
  color: var(--starlight-color-light);
  border: 1px solid var(--starlight-color-light)
}



.primary-button.theme-4 {
  background-color: transparent;
  color: var(--wepredic-color);
  border: 1px solid var(--wepredic-color)
}

.primary-button.theme-4:hover {
  background-color: var(--wepredic-color);
  color: white;
  border: 1px solid var(--wepredic-color)
}

.primary-button.theme-5 {
  background-color: var(--eurosafe-color);
  color: white;
  border: 1px solid var(--eurosafe-color)
}

.primary-button.theme-5:hover {
  background-color: white;
  color: var(--eurosafe-color);
  border: 1px solid var(--eurosafe-color)
}

.subtitle {
  font-weight: bold;
  font-size: 24px;
  color: #000
}

.circle {
  height: 40px;
  width: 40px;
  border-radius: 150px;
  border: 2px solid #000;
}

.title {
  color: var(--wepredic-color);
  font-weight: 700;
  padding: 10px;
}

.bg-navbar {
  background-color: #ffffffc1;
  /* box-shadow: 1px 1px 8px 5px #acacac12; */

}


.part-title {
  font-weight: 500;
  font-size: 24px;
}

.part-title.subtitle {
  font-weight: 500;
  font-size: 20px !important
}

.text-grey {
  color: rgb(152, 152, 152) !important
}

.text-light-grey {
  color: #FFFFFF
}

.my-card {
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background-color: #FFFFFF;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

}

.card-image {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 40%;
  overflow: hidden;
}

.bg-theme {
  background-color: var(--wepredic-color);
}

.bg-theme .part-title {
  color: #C9CACCE5;
}

.text-biopredic {
  color: var(--biopredic-color)
}

.text-starlight {
  color: var(--starlight-color-light)
}

.text-eurosafe {
  color: var(--eurosafe-color)
}

.text-wepredic {
  color: var(--wepredic-color) !important
}

.text-wepredic-dark {
  color: var(--wepredic-dark) !important
}

.bg-biopredic {
  background-color: var(--biopredic-color) !important
}

.bg-wepredic {
  background-color: var(--wepredic-color) !important
}

.bg-wepredic-dark {
  background-color: var(--wepredic-dark) !important
}

.bg-wepredic-light {
  background-color: var(--wepredic-dark-light) !important
}

.bg-starlight-dark {
  background-color: var(--starlight-color) !important
}

.bg-starlight {
  background-color: var(--starlight-color-light) !important
}

.bg-eurosafe {
  background-color: var(--eurosafe-color) !important
}

.bg-dark-blue {
  background-color: #060024
}

.bg-light-grey {
  background-color: #f6f6f6;
}

.partners .carousel-item-img {
  display: block;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 100%
}


.product-preview .carousel-item {

  height: 100%;
  padding: 0
}

.product-preview .slick-slide {
  display: grid !important;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  height: 100%;
}

.product-preview .slick-list {
  display: grid !important;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  height: 100%;
}

.partners .carousel-item {
  border-radius: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
}

.partners .slick-slide {
  margin: 0 20px;
  border-radius: 100%;
  aspect-ratio: 1 / 1;
  width: 150px;
  border-radius: 100%;
  border: 1px solid #0013184D;
  padding: 20px
}


.partners .slick-list {
  margin: 0 -20px;
}


.my-badge {
  font-weight: 500 !important;
  letter-spacing: 1.5px;
  padding: 12.5px !important
}


.uge-letter {
  font-size: 56px;
}

.big-letter {
  font-size: 31px;
}

.testimonial-item {
  position: relative;
  padding: 0 30px;
}

.testimonial-content:after {
  position: absolute;
  content: "”";
  right: 0;
  font-size: 60px;

}

.testimonial-content:before {
  position: absolute;
  content: "“";
  top: -10px;
  left: 0px;
  font-size: 60px;
}

.dropdown {
  background-color: white;
  z-index: 999 !important;
  box-shadow: 3px 3px 12px rgba(151, 151, 151, 0.1);
  clip-path: polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%);
}

.nav-link {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.underline-0 {
  text-decoration: none;
}


.side-filter .title {
  font-size: 14px;
  padding: 0;
  color: #000 !important;
  margin: 10px 0;
}

.side-filter .label {
  font-size: 14px;
  color: #000;

}

input[type=checkbox] {
  padding: 8px;
  margin-right: 5px;
}


.input-box {
  position: relative
}

.form-icon {
  height: 1rem;
  width: 1rem;
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 10px;
  color: grey;
  transform: translateY(-50%);
}

.my-input {
  box-sizing: border-box;
  padding-left: 1rem;
}



.product-card:hover>.product-img-container>.on-hover-product {
  opacity: 1;
  transition: all 0.3s;
}

.on-hover-product {
  opacity: 0;
  background-color: var(--wepredic-dark-transparent);
  width: 100%;
  padding: 15px 0;
  text-align: center;
  position: absolute;
  color: white;
  bottom: 0;
  cursor: pointer;
}

.product-img-container {
  position: relative;
}

.loader {
  background-color: #ffffffed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-behavior: unset;
}

svg.dna-wrapper circle:nth-child(odd) {
  fill: var(--wepredic-color) !important;
}

svg.dna-wrapper circle:nth-child(even) {
  fill: var(--wepredic-dark) !important;
}


.product-img-small-card {
  height: 20% !important;
  width: 20% !important;
  border-radius: 6px;
  border: 1px solid #c1c1c1;
  background-color: white;
}

.nav-tabs .nav-link.active {
  border: 0 !important;
  border-bottom: solid 1px #000 !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border-color: none !important;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link:hover {
  color: gray
}

.nav-tabs .nav-link {
  color: gray
}

.dropdown-btn {
  box-shadow: none !important;
  background-color: transparent;
}


.dropdown-menu::before {
  content: "";
  position: absolute;
  right: 16px;
  top: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 13px 13px 13px;
  border-color: transparent transparent #F5F5F5 transparent;
  z-index: 999999;
}


.dropdown-menu {
  background: #F5F5F5 !important;
  box-shadow: 5px 5px 5px 0px rgba(82, 82, 82, 0.162);
  float: left;
  position: absolute;
  margin-right: -20px !important;
  border: 0 !important;
  top: 1em !important;
  z-index: 99999;
}

.dropdown-toggle-btn:focus {
  box-shadow: none !important;
  border: 0;
}

.dropdown-toggle-btn {
  background-color: transparent;
}

.article-card {
  border-radius: 16px;
  background-color: #F5F5F5;
  padding: 1.5em;
}

.wp-block-heading {
  font-size: 14px !important;
  font-weight: 600;
  padding: 20px 0 10px 0;
  text-transform: uppercase;
}

h1.wp-block-heading {
  font-size: 18px !important;
  font-weight: 600;
  padding: 20px 0 5px 0;
  text-transform: none;
}

.eurosafe .wp-block-heading {
  color: var(--eurosafe-dark) !important;
}

.eurosafe strong {
  color: var(--eurosafe-color) !important;
}

.biopredic .wp-block-heading {
  color: var(--biopredic-dark) !important;
}

.biopredic strong {
  color: var(--biopredic-color) !important;
}

.post>p {
  color: rgb(41, 41, 41) !important;
}

.post>h2 {
  color: #0F0 !important;
}

.wp-block-button {
  text-align: center;
}

.wp-block-button>a {
  color: white !important;
  text-decoration: none !important;
  padding: 10px 20px;
  border-radius: 16px;
  display: inline-block;
  text-align: center;
}

.eurosafe .wp-block-button>a {
  background-color: var(--eurosafe-color);
}

.biopredic .wp-block-button>a {
  background-color: var(--biopredic-color);
}

.post .wp-block-image img {
  width: 100%;
}

.breadcrump-item {
  text-decoration: none;
  color: #060024;
}

.pin {
  background-color: var(--wepredic-color);
  width: 20px !important;
  height: 20px !important;
  line-height: 21px;
  font-size: 10px;
  margin-left: -10px;
  text-align: center;
  border-radius: 50%;
  margin-top: 5px;
}

.cart-pin {
  background-color: var(--wepredic-color);
  color: white;
  width: 15px !important;
  height: 15px !important;
  line-height: 15px;
  font-size: 8px !important;
  font-weight: bold;
  margin-left: -7px;
  text-align: center;
  border-radius: 50%;
  right: 0;
}

.arrow>.pin {
  width: 10px !important;
  height: 10px !important;
  font-size: 10px;
  margin-left: -5px;
  text-align: center;
  border-radius: 50%;
}

.underline {
  text-decoration: underline;
}

.border-start.border-light {
  border-left: 0.1px solid #c9caccaf !important;
}

.arrow .thick {
  display: block;
  height: 1px;
  border: 0 !important;
  border-top: 2px solid #fff !important;
  margin: 9px 0;
  padding: 0;
}

.news-bg {
  position: relative;
}

.news-bg::before {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  filter: brightness(50%);
}

.news-bg-after {
  height: 100%;
  width: 100%;
  background-color: #00000028;
}

.height-fixed {
  max-height: attr(data-height) !important;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile+label {
  color: var(--wepredic-color);
  display: inline-block;
  width: 100%;
  border: 1px solid var(--wepredic-color);
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 16px;
  text-align: center;
}

.inputfile+label:hover {
  color: white;
  background-color: var(--wepredic-color);
  display: inline-block;
  border: 1px solid var(--wepredic-color);
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 16px;
  transition: all 0.3s;
}

.radius-small {
  border-radius: 12px !important;
  font-size: 12px !important;
}

.img-wrap {
  position: relative;
  overflow: hidden;
}

.img-wrap>img {
  position: absolute;
  height: 100%;
  max-width: 100% !important;
  object-fit: contain;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 50%;
  height: auto;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
}

.starlight {
  .title {
    color: var(--starlight-color) !important;
  }
  

  .pin{
    background-color: var(--starlight-color-light) !important;
    color: var(--starlight-dark);
    box-shadow: 1px 3px 8px 2px rgba(82, 82, 82, 0.324);

    
  }
}

.eurosafe {
  .title {
    color: var(--eurosafe-color) !important;
  }
  

  .pin{
    background-color: var(--eurosafe-color) !important;
    color: var(--eurosafe-dark);
    box-shadow: 1px 3px 8px 2px rgba(82, 82, 82, 0.324);

    
  }
}

.biopredic {
  .title {
    color: var(--biopredic-color) !important;
  }
  

  .pin{
    background-color: var(--biopredic-color) !important;
    color: var(--biopredic-dark);
    box-shadow: 1px 3px 8px 2px rgba(82, 82, 82, 0.324);

    
  }
}